import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import Button from 'components/Button';
import Form from 'components/Form';
import { Input, FormRow } from 'components/FormControls';
import Link from 'components/Link';
import Logo from 'components/Logo';
import Layout from 'containers/Layout';
import { AuthLayout } from 'widgets/layouts/auth-layout';
import { MainLayout } from 'widgets/layouts/main-layout';
import { login } from 'entities/auth';
import LOCALES from 'shared/constants/LOCALES';
import LOGO from 'shared/constants/LOGO_SOURCES';
import routes from 'shared/helpers/routes';
import { required, composeValidators, validNoWhitespace } from 'shared/helpers/validations';
import { withTranslation } from '../../src/i18n';

interface PageProps {
  login: (data: any, url: string | string[]) => any;
  router: Router;
  t: (name: string, optons?: any) => string;
}

class LoginPage extends Component<PageProps> {
  login = (data) => {
    const { next: nextUrl } = this.props.router.query;

    return this.props.login(data, nextUrl);
  };

  render() {
    return (
      <MainLayout withoutLayout withoutModal>
        <Layout title="Авторизация" disableLayout>
          <AuthLayout.Wrapper>
            <AuthLayout.FormWrapper>
              <AuthLayout.LogoWrapper>
                <Logo src={LOGO.WITH_BLACK_NAME} />
              </AuthLayout.LogoWrapper>
              <AuthLayout.FormWrapperInner>
                <AuthLayout.Title>Вход в личный кабинет</AuthLayout.Title>
                <Form
                  t={this.props.t}
                  translationPrefix="common:auth."
                  onSubmit={this.login}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} method="post">
                      <AuthLayout.Fields>
                        <FormRow>
                          <Field name="username" validate={composeValidators(required, validNoWhitespace)}>
                            {({ input, meta }) => <Input label="Почта" meta={meta} {...input} />}
                          </Field>
                        </FormRow>
                        <FormRow>
                          <Field name="password" validate={required}>
                            {({ input, meta }) => (
                              <Input label="Пароль" type="password" meta={meta} {...input} />
                            )}
                          </Field>
                        </FormRow>
                        <FormRow>
                          <Link href={routes.forgot}>Забыли пароль?</Link>
                        </FormRow>
                      </AuthLayout.Fields>
                      <AuthLayout.Buttons>
                        <AuthLayout.Button>
                          <Button fluid type="submit">
                            Войти
                          </Button>
                        </AuthLayout.Button>
                        <AuthLayout.Button>
                          <Link href={routes.registration}>
                            <Button fluid secondary type="button">
                              Регистрация
                            </Button>
                          </Link>
                        </AuthLayout.Button>
                      </AuthLayout.Buttons>
                    </form>
                  )}
                />
              </AuthLayout.FormWrapperInner>
            </AuthLayout.FormWrapper>
            <AuthLayout.BG />
          </AuthLayout.Wrapper>
        </Layout>
      </MainLayout>
    );
  }
}

const mapDispatchToProps = {
  login,
};

export default withRouter(connect(null, mapDispatchToProps)(withTranslation([LOCALES.COMMON])(LoginPage)));
