import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  min-height: 100vh;
`;

const BG = styled.div`
  width: 50%;
  display: flex;
  background-image: url('/static/login/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: ${BREAKPOINTS.MD}) {
    display: none;
  }
`;

const FormWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (min-width: 1365px) and (max-width: 1439px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINTS.MD}) {
    padding: 55px 16px;
    width: 100%;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 80px;
  width: 284px;
  height: 64px;

  & > img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: ${BREAKPOINTS.MD}) {
    margin-bottom: 55px;
    width: 193px;
    height: 40px;
  }
`;

const FormWrapperInner = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0 0 40px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.MD}) {
    font-weight: 400;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormHaveAccount = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const FormPrivacy = styled.div`
  margin-top: 16px;
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
  margin: 0px -4px 0;
`;

const Button = styled.div`
  margin: 0 4px;
  width: 100%;
`;

const Remember = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;

  & > span {
    margin-right: 4px;
    margin-left: 4px;
  }
`;

const RememberLabel = styled.span`
  margin-right: 4px;
`;

const RegisterButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
`;

const Text = styled.div`
  text-align: center;
`;

export default {
  Wrapper,
  BG,
  FormWrapper,
  LogoWrapper,
  FormWrapperInner,
  Title,
  Fields,
  FormHaveAccount,
  FormPrivacy,
  Buttons,
  Button,
  Remember,
  RememberLabel,
  RegisterButtonWrapper,
  Text,
};
