import { withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LoginPage from './LoginPage';

export async function getServerSideProps({ locale }) {
  return {
    props: {
      disableLayout: true,
      ...(await serverSideTranslations(locale)),
      // Will be passed to the page component as props
    },
  };
}

export default withTranslation()(LoginPage);
